import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import below from '../../images/below.jpg'
import Robaticscss from "../../css/robaticscss";
import img1 from '../../images/robotics1.jpg'
import img2 from '../../images/robotics2.jpg'
import img3 from '../../images/robotics3.jpg'

const Robatics=()=>{
    return(
        <Robaticscss>
         <Navbar1/>
         <Navbar2/>
        {/* <Navigation/> */}
        <h3>Robatics Peripherals</h3>
        <div className="company-profile-par">

        <p>The reliability of industrial robots has improved substantially over the years and Effica offers turnkey robotic that supports a variety of robotic applications like arc welding, spot welding, machine loading, and palletizing, which utilize robotic grippers, and robotic tooling. Integration of robotics that are 4 or 6-axis articulated models are flexible and noted for delivering a unique combination of cost efficiency and performance benefits besides preciseness. Faster cycle times and an extended working range for machine tending, material handling, assembly and many other industrial needs.</p>

        <div className="">
        <img className="img01" src={img1} alt="img loading"></img>
        <img className="img02" src={img2} alt ="img loading"></img>
        <img className="img03" src={img3} alt = "img loading"></img>

        </div>
       

        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        <footer className='footer'></footer>

        </Robaticscss>

    );
}

export default Robatics;