import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import below from '../../images/below.jpg'
import Marketcss from "../../css/market-servedcss";

const Market =()=>{
    return (
        <Marketcss>

        <Navbar1/>
        <Navbar2/>
        {/* <Navigation/> */}
        <div className="container">

        
        <h3>Market served</h3>
        <div className="company-profile-par">

        <p><b>Effica’s Innovative Solution </b>has proven themselves across the following industrial sectors.</p>
        <ul>
            <li>Engineering Component Manufacturers</li>
            <li>Automotive Component Manufacturers</li>
            <li>Electrical Component Manufacturers</li>
            <li>FMCG Industries</li>
            <li>Pharma, Cosmetics, Food & Beverages</li>
        </ul>

        <p className="margin">
        Backed by a team of well qualified and experienced engineers we are committed to serve our customers unique needs. We continually develop our analytical, design & development skills to
        integrate cutting edge systems that contribute to improve process& productivity besides reducing downtime and saving operation cost.
        </p>
        <h4>Our work Methodology</h4>
        <p >
        –  Industrial Engineering Analysis<br/>
        –  System Layout and Design<br/>
        –  Mechanical and Electrical Engineering<br/>
        –  Equipment Specification and Procurement<br/>
        –  Custom equipment design and Manufacturing<br/>
        –  Control Systems<br/>
        –  Factory Acceptance testing<br/>
        –  Installation, Start-up and Training<br/>
        –  Validation and Documentationv<br/>
        </p>


        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        </div>
        <footer className='footer'></footer>


        </Marketcss>
    );
}

export default Market;