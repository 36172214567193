import styled from "styled-components";

const NavbarCss1 = styled.nav`

.others{
    width: 100%;
    margin-bottom: 3%;
}

@media screen and (max-width: 960px){

   
    
.others{
    width: 100%;
}
}

`
export default NavbarCss1;