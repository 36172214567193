import styled from "styled-components";


const NavbarCss = styled.nav`

.home{
    width: 100%;
    margin-bottom:2% ;
}

.footer{
    width: 100%;
}

@media screen and (max-width: 960px){

   
    
    .home{
        width: 100%;
        
    }

   
}

`
export default NavbarCss;
