import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import below from '../../images/below.jpg'
import Ourteam from "../../css/our-teamcss";
import arun from '../../images/arjun_prakash.gif'

const OurTeam=()=>{
    return(
        <Ourteam>

    <Navbar1/>
    <Navbar2/>
    <div className="container">

   
        {/* <Navigation/> */}
        <h3 className="topic">Our Team</h3>
        <div className="company-profile-par">
        <h3 className="heading">Management</h3>

        <p>
        Our talented team is our valuable asset and they remain the main reason for our success. The team constitutes of experienced professionals who are proficient in manufacturing, design, process control, and in techno commercial facet that are essential to excel. With extensive background of industry experts we meet your manufacturing demands by providing innovative, yet practical solutions
        </p>
        <img className="pic" src={arun} alt="img loading" />
        <p className="name"><b className="heading">Mr. V.Arjun Prakash</b>, President</p>
        <p className="details">
     

            Specialising in Industrial Automation since 1998, has spent 5 years in Germany gaining a Masters Degree in Automation from FHT-Esslingen, Germany and has working experience in Festo AG, Esslingen, & IMT Robots AG, Stuttgart, Germany.
        </p>

        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>

        </div>

        <footer className='footer'></footer>



        </Ourteam>
    );
}

export default OurTeam;