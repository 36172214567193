import './App.css';
import Navbar from './components/navbar'
// import Navigation from './components/navigation';
import Wrapper from "./css/landingcss"
import Home from './pages/home';
import Navbar2 from './drop/Navbar';




const Landing = ()=>{
    return ( 
  
    <Wrapper>
        <Navbar/>
        <Navbar2/>

        
        {/* <Navigation/> */}
        <Home/>
       
    </Wrapper>
    )
};

export default Landing;