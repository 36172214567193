import Navbar1 from "../components/navbar1";
import Navbar2 from "../drop/Navbar";
// import Navigation from "../components/navigation";
import below from '../images/below.jpg'
import img1 from '../images/accomplishment/accomplishment1_small.jpg'
import img2 from '../images/accomplishment/accomplishment2_small.jpg'
import img3 from '../images/accomplishment/accomplishment3_small.jpg'
import img4 from '../images/accomplishment/accomplishment4_small.jpg'
import img5 from '../images/accomplishment/accomplishment5_small.jpg'
import img6 from '../images/accomplishment/accomplishment6_small.jpg'
import img7 from '../images/accomplishment/accomplishment7_small.jpg'
import img8 from '../images/accomplishment/accomplishment8_small.jpg'
import img9 from '../images/accomplishment/accomplishment9_small.jpg'
import img10 from '../images/accomplishment/accomplishment10_small.jpg'
import img11 from '../images/accomplishment/accomplishment11_small.jpg'
import Accomplishmentscss from "../css/accomplishmentscss";


const Accomplishments=()=>{
    return(
        <Accomplishmentscss>
        <Navbar1/>
        <Navbar2/>
        {/* <Navigation/> */}

        <div className="container">

       
        <h3>Accomplishments</h3>
        <div className="company-profile-par">

        
        <div className="images">
        <img className="img01" src={img1} alt="img loading"></img>
        <p className="p1">Pick & Place System for Press</p>
        <img className="img02" src={img2} alt ="img loading"></img>
        <p className="p2">Automatic Feeding System to Forging Press</p>
        <img className="img03" src={img3} alt = "img loading"></img>
        <p className="p3">Pick & Place System</p>
        <img className="img04" src={img4} alt="img loading"></img>
        <p className="p1">Endurance Testing Machine</p>
        <img className="img05" src={img5} alt ="img loading"></img>
        <p className="p2">Performance Flow Test Machine</p>
        <img className="img06" src={img6} alt = "img loading"></img>
        <p className="p3">Drilling & Counter Boring Machine</p>
        <img className="img07" src={img7} alt="img loading"></img>
        <p className="p1">Stage Case Assembly Machine</p>
        <img className="img08" src={img8} alt ="img loading"></img>
        <p className="p2">Pole Marking & Cutting Machine</p>
        <img className="img09" src={img9} alt = "img loading"></img>
        <p className="p3">Automatic Conveying & Bag Palletizing Machine</p>
        <img className="img10" src={img10} alt="img loading"></img>
        <p className="p1">Transmission Testing Station</p>
        <img className="img11" src={img11} alt ="img loading"></img>
        <p className="p2">Transmission Testing Machine</p>
        </div>
       

        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        </div>

        <footer className='footer'></footer>


        </Accomplishmentscss>

    );
}

export default Accomplishments;