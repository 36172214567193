import styled from "styled-components";

const Marketcss= styled.div`

.margin{
    margin-top: 1rem;
}

ul>li{
    margin-top:0.5rem;
}



*{
    font-family: Arial, Helvetica, sans-serif;

}

.company-profile-par{
    padding: 0.5rem 0.2rem;
    margin-bottom: 2.5rem;
    border:1px;
    border-style: ridge;
    border-color: lightgrey;

    
    margin-left: 28rem;
    margin-top: 1rem;
    width:42.5rem;
   

    font-size: 12px;
    color: #000000;
    text-decoration: none;
    line-height: 18px;
    text-align: justify;
}
    

.company-profile-par>p,h4{
    padding: 0.58rem;

}

h3{
    margin-top: -24%;
    margin-left: 28rem;
    color:rgb(2,0,104);
}

.imgbelow{
    position: absolute;
    
    margin-top:-11.5rem;
    margin-left: 10rem;
    width: 11.5rem;
    
}

.vl {
  border-left: 1px ridge lightgrey;
  height: 580px;
  position: absolute;
  left: 25.2rem;
  margin-left: -3px;
  margin-top: 18rem;
  top: 0;
}

.footer{
    height: 3rem;
    background-color: rgb(82,86,98);
    margin-top: 1rem;
    
}

@media screen and (max-width: 960px){

    .container{
        display: flex;
        flex-direction: column;
    }
    .company-profile-par{
        width: 75%;
        margin: 10% 5%;
    }
    .imgbelow{
        display: none;
    }
    .vl{
        display: none;
    }
    h3{
        margin: 5% 0;
    }
    .footer{
        margin-top: 85%;
    }
}

`
export default Marketcss;