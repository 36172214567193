export const MenuItems = [
  {
    title: 'Company Profile',
    path: '/company-profile',
    cName: 'dropdown-link'
  },
  {
    title: 'Market Served',
    path: '/market-served',
    cName: 'dropdown-link'
  },
  {
    title: 'Our Team',
    path: '/our-team',
    cName: 'dropdown-link'
  },
  {
    title: 'Management',
    path: '/our-team',
    cName: 'dropdown-link'
  }
];
