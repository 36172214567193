import welcome from '../images/welcome.gif'
import robatics from '../images/robatics-1.jpg'
import automation from '../images/automation-solutions-2.jpg'
import material from '../images/material-handling-3.jpg'
import project from '../images/project-management.gif'
import partner from '../images/certified-partner.gif'
import Homecss from '../css/homecss'
import { Link } from 'react-router-dom'


const Home =()=>{
    return(
        <Homecss>

           
           <div className='container'>
            <img src={welcome} alt="img loading" className="welcome"/>
            <Link to="/robatics" ><img src={robatics} alt="img loading" className="robatics"/></Link>
            <Link to="/automation" ><img src={automation} alt="img loading" className="automation"/></Link>
            
            <Link to="/material" ><img src={material} alt="img loading" className="material"/></Link>
            <div className='border'></div>
            <img src={project} alt="img loading" className="project"/>
            <img src={partner} alt="img loading" className="partner"/>
            
          
            <p className='par1'>
            <h4>Our Associates</h4>
                <b>Team Technik </b>- Marketing & Service Partners<br/>
                <b>ABB Robotics </b>- Certified Channel Partners<br/>
                <b>Neubauplan </b>- Design Consultants <br/>
                <b>Soliton, Bangalore</b> - Machine Vision Partners<br/>
            </p>
           
            <ul className='homeul'>
            <h4>Our Service Sector</h4>
                <li>Engineering Component Manufacturers</li>
                <li>Automotive Component Manufacturers</li>
                <li>Electrical Component Manufacturers</li>
                <li>FMCG Industries</li>
                <li>Pharma, Cosmetics, Food & Beverages</li>
                <li>Others..</li>
            </ul>
           
            <footer className='footer'></footer>
            </div>
           
           
        
        </Homecss>
    );
}

export default Home;