import { Link } from "react-router-dom";


const Links2=()=>{
    return(
        <>
        <li><Link to="/robatics">Robatics & peripherals</Link></li>
        <li><Link to="/automation">Automation Solution</Link></li>
        <li><Link to="/material">Material Handling</Link></li>

        </>
        
    );
}

export default Links2;