import styled from "styled-components";

const Materialcss = styled.div`


*{
    font-family: Arial, Helvetica, sans-serif;

}

.company-profile-par{
    padding: 0.5rem 0.2rem;
    margin-bottom: 2.5rem;
    border:1px;
    border-style: ridge;
    border-color: lightgrey;

    
    margin-left: 28rem;
    margin-top: 1rem;
    width:42.5rem;
   

    font-size: 12px;
    color: #000000;
    text-decoration: none;
    line-height: 18px;
    text-align: justify;
}
    

.company-profile-par>p,.heading{
    padding: 0.58rem;

}

.company-profile-par{
    height: 60rem;
}

.pic{
    padding: 0.60rem;
    margin-left: 4rem;
    width: 13rem;
}

.pic1{
    position: absolute;
    margin-left: 4rem;
margin-top: 0.57rem;
width:13rem;


}


.heading{
    color: red;
    margin-left: 4rem;
}

.heading1{
color: red;
position: absolute;
margin-left: 22.2rem;
margin-top: -1.6rem;
}

.heading2{
    color: red;
    margin-left: 1rem;

}

.heading3{
    color: red;
    margin-left: 1rem;

}




.name{
    position: absolute;
    margin-top: -10.35rem;
    margin-left: 10rem;
}
.details{
    position: absolute;
   
    text-align: justify;
    width: 35rem;
    margin-left: 3rem;
    
}

.p{
    margin-left: 1rem;
}

.topic{
    margin-top: -19%;
    margin-left: 28rem;
    color:rgb(2,0,104);
}

.topic1{
    color:rgb(2,0,104);
    margin-top: 15rem;
    margin-left: 1rem;
    font-size: 18px;
}



.imgbelow{
    position: absolute;
    
    margin-top:-11.5rem;
    margin-left: 10rem;
    width: 11.5rem;
    
}

.vl {
  border-left: 1px ridge lightgrey;
  height: 1070px;
  position: absolute;
  left: 25.2rem;
  margin-left: -3px;
  margin-top: 18rem;
  top: 0;
}

.footer{
    height: 3rem;
    background-color: rgb(82,86,98);
    margin-top: 1rem;
    
}

@media screen and (max-width: 960px){
        .container{
            display: flex;
            flex-direction: column;
            margin: 0;
        }
        .company-profile-par{
            margin: 5% 10%;
            width: 85%;
        }
        .details{
            margin: 0;
            width: 85%;
            margin-top: 60%;
        }
        .imgbelow{
            display: none;
        }

        .footer{
            margin-top: 350%;
        }

        .topic1{
            margin-top: 200%;
        }
        .heading{
            margin: 0;
        }
        .heading1{
            margin: 50% 5%;
        }

        .heading2{
            margin: 0;
        }
        img{
            margin-bottom: 5%;
        }

        .vl{
            display: none;
        }
        .topic{
            margin: 0;
        }
        .p{
            margin-top: 5%;
        }
        .pic{
            margin: 0 2%;
        }
        .pic1{
            margin:60% 10% 0 -57%;
        }
      
}


`
export default Materialcss;