import NavbarCss1 from "../css/navbarcss1"
import others from '../images/otherpage.jpg'
// import logo from '../images/logo.jpg'
// import top from '../images/top-products.jpg'

const Navbar1 =()=>{
    return(
        <NavbarCss1>
        <div>
            {/* <img className='logo' src={logo} alt="logo img" />
            <img className='top' src={top} alt="top img" /> */}
            <img className="others" src={others} alt="img will appear"/>
        </div>
        </NavbarCss1>
    )
}

export default Navbar1;