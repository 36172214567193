import {BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Landing from "./landingpage";
import Company from './pages/about/company-profile';
import Infrastructure from './pages/infrastructure';
import OurTeam from './pages/about/our-team';
import Jobs from './pages/careers/jobs-at-effica';
import Customers from './pages/customers';

import Market from './pages/about/market-served';
import Robatics from './pages/our-service/robatics-peripherals';
import Automation from './pages/our-service/automation-solutions';
import Material from './pages/our-service/material-handling';
import Accomplishments from './pages/accomplishments';
import Links from './pages/about-us3';
import Links2 from './pages/our-service/our-service3';



function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing/>} />
          <Route path="/company-profile" element={<Company/>} />
          <Route path="/market-served" element={<Market/>} />
          <Route path="/our-team" element={<OurTeam/>} />
          <Route path="/robatics" element={<Robatics/>} />
          <Route path="/automation" element={<Automation/>} />
          <Route path="/material" element={<Material/>} />
          <Route path="/offshore" element={<Material/>} />
          <Route path="/accomplishments" element={<Accomplishments/>} />
          <Route path="/infrastructure" element={<Infrastructure/>} />
          <Route path="/customers" element={<Customers/>} />
          <Route path="/jobs" element={<Jobs/>} />
          <Route path="/people" element={<Material/>} />
          <Route path="/life" element={<Jobs/>} />
          <Route path="/about" element={<Links/>} />
          <Route path="/links2" element={<Links2/>} />

        </Routes>
    </BrowserRouter>
      
   
  );
}

export default App;
