import styled from "styled-components";

const Homecss = styled.div`

body{
    background-color:  rgb(251,251,251);

    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    
}

.container{
    margin-top: -21%;
    z-index: -1;
    position: absolute;
}

.border{
    border: 0.01px;
    border-style: dashed ;
    border-color: lightgrey;
    width: 70%;
    margin-left: 15%;
}


.welcome{
    position: absolute;
    
    margin: 1.5% 0 1.5% 408px;

    
}

.robatics{
    margin:4.7rem 2rem 1rem 25.4rem;
    border-right: 1px;
    border-left: 0;
    border-bottom: 0;
    border-top: 0;
    padding-right: 2rem;
    border-style: dashed;
    border-color: grey;
}
.automation{
    margin:4.7rem 2rem 1rem 0rem;
    border-right: 1px;
    border-left: 0;
    border-bottom: 0;
    border-top: 0;
    padding-right: 2rem;
    border-style: dashed;
    border-color: grey;

}

.material{
    margin:4.7rem 4rem 1rem 0rem;

}

.project{
    margin:2.9rem 2rem 2rem 13.3rem;
    
}

.partner{
    position: absolute;
    margin: 2rem 2rem 1rem 3.8rem;
}

.par1{
    position: absolute;
    font-size: 13px;
    font-weight: 400;
  
    text-align:left;
    width: 17rem;
    margin:-8rem 2rem 2rem 35rem;
}

.par1>h4{
    margin: -0.5rem 0 1rem 0;
}

.homeul{
    position: absolute;
    margin: -14.2rem 0 0 54rem;
    font-size: 13px;
}

.homeul>h4{
    text-align: left;
    margin:0 0 1rem -1rem ;
}

.homeul>li{
    margin: 0.5rem 0.5rem ;
}

.footer{
    height: 3rem;
    width: 125%;
    background-color: rgb(82,86,98);
}

@media screen and (max-width: 960px){
   

    .robatics{
        margin-top: 10%;
        margin: 100% 0 1.5% 0;

}
.Vl{
    display: none;
}

.par1{
    margin: 600% 0 10% 10%;
}
.homeul{
    margin: 500% 0 10% 0;
}
.project{
    margin: 25% 0 10% 0;
}

.partner{
    margin: 60% 0 10% 0;
}

.welcome{
    margin: 35% 0 -5% 0;
}

.container{
    display: flex;
    flex-direction: column;
   
}
.container{
    margin-left: 8%;
    width: 85%;
}

.footer{
    width: 130%;
    margin-left: -20%;
    margin-top: 240%;
}




}


`
export default Homecss;