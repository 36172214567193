import Automationcss from "../../css/automationcss";
import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import below from '../../images/below.jpg'

const Automation=()=>{
    return(
        <Automationcss>
        <Navbar1/>
        <Navbar2/>
        {/* <Navigation/> */}
        <h3>Automation Solutions</h3>
        <div className="company-profile-par">

        <p>	
            In today’s challenging environment, every manufacturer constantly searches for ways to increase productivity and establish competitive advantage. We at Effica recognize the requirements and provide complete turnkey for demanding manufacturing and assembly applications which include manufacturing of engineering component, automotive component, electrical component, FMCG Industries, Pharma, Cosmetics, Food & Beverages. We with our team of highly knowledgeable persons design & develop custom assembly automation system that delivers repeatability, fast cycle times and unmatched reliability.</p>
        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        <footer className='footer'></footer>

        </Automationcss>

    );
}

export default Automation;