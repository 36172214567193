import styled from "styled-components"

const Wrapper = styled.main`
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');


/* 
.mainimg{
    margin: 0.05rem 10rem 0 25.45rem;
    position: absolute;
} */

`

export default Wrapper;