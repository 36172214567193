import styled from "styled-components";

const Automationcss= styled.div`

*{
    font-family: Arial, Helvetica, sans-serif;

}

.company-profile-par{
    padding: 0.5rem 0.2rem;
    margin-bottom: 2.5rem;
    border:1px;
    border-style: ridge;
    border-color: lightgrey;

    
    margin-left: 28rem;
    margin-top: 1rem;
    width:42.5rem;
   

    font-size: 12px;
    color: #000000;
    text-decoration: none;
    line-height: 18px;
    text-align: justify;
}
    

.company-profile-par>p,h4{
    padding: 0.58rem;

}

.company-profile-par{
    height: 32rem;
}

h3{
    margin-top: -19.5%;
    margin-left: 28rem;
    color:rgb(2,0,104);
}

.imgbelow{
    position: absolute;
    
    margin-top:-13rem;
    margin-left: 10rem;
    width: 11.5rem;
    
}

.vl {
  border-left: 1px ridge lightgrey;
  height: 620px;
  position: absolute;
  left: 25.2rem;
  margin-left: -3px;
  margin-top: 18rem;
  top: 0;
}

.footer{
    height: 3rem;
    background-color: rgb(82,86,98);
    margin-top: 1rem
}

@media screen and (max-width: 960px){
    .company-profile-par{
        margin: 10% 5%;
        width: 85%;
    }
    .imgbelow{
        display: none;
    }
    .vl{
        display: none;
    }
    h3{
        margin: 0;
    }
    
}

`
export default Automationcss;