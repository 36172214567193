import Navbar1 from "../components/navbar1";
import Navbar2 from "../drop/Navbar";
// import Navigation from "../components/navigation";
import below from '../images/below.jpg'
import img1 from '../images/infrasture/infra1.jpg'
import img2 from '../images/infrasture/infra2.jpg'
import img3 from '../images/infrasture/infra3.jpg'
import img4 from '../images/infrasture/infra4.jpg'
import img5 from '../images/infrasture/infra5.jpg'
import img6 from '../images/infrasture/infra6.jpg'
import Infracss from "../css/infracss";


const Infrastructure =()=>{
    return(
        <Infracss>
         <Navbar1/>
         <Navbar2/>
        {/* <Navigation/> */}

        <div className="container">

        
        <h3>Infrastructure</h3>
        <div className="company-profile-par">

        <p>
            We have state-of-the art infrastructure that currently sprawls over an area of 10,000 m2 land. We have a well equipped work environment which includes In-house Machine Shop, Assembly shop, Design Department, Controls Department. Our entire department encompasses team of energetic personnel to execute the project as per customer requirement. With a pool of proficient engineers we deliver expected results.
        </p>
        <div className="images">
        <img className="img01" src={img1} alt="img loading"></img>
        <img className="img02" src={img2} alt ="img loading"></img>
        <img className="img03" src={img3} alt = "img loading"></img>
        <img className="img04" src={img4} alt = "img loading"></img>
        <img className="img05" src={img5} alt = "img loading"></img>
        <img className="img06" src={img6} alt = "img loading"></img>

        </div>
       

        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        </div>
        <footer className='footer'></footer>

        </Infracss>
    );
}

export default Infrastructure;