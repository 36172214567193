import NavbarCss from "../css/navbarcss"
import home from '../images/home page.jpg'
// import logo from '../images/logo.jpg'
// import top from '../images/top-products.jpg'


const Navbar =()=>{
    return(
        <NavbarCss>
        <div>
            <img style={{width:"100%"}} className="home" src={home} alt="img will appear"/>
         
        </div>
        </NavbarCss>
    )
}

export default Navbar;