import { Link } from "react-router-dom";


const Links=()=>{
    return(
        <>
        <div>
        <li><Link to="/company-profile">Company Profile</Link></li>
        <li><Link to="/market-served">Market Served</Link></li>
        <li><Link to="/our-team">Management</Link></li>
        </div>
        </>
        
    );
}

export default Links;