export const MenuItems1 = [
    {
      title: 'Robotics & peripherals',
      path: '/robatics',
      cName: 'dropdown-link'
    },
    {
      title: 'Automation Solutions',
      path: '/automation',
      cName: 'dropdown-link'
    },
    {
      title: 'Material Handling',
      path: '/material',
      cName: 'dropdown-link'
    },
    {
      title: 'Offshore Services',
      path: '/offshore',
      cName: 'dropdown-link'
    }
  ];