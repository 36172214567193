import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import below from '../../images/below.jpg'
import Jobscss from "../../css/careerscss";

const Jobs=()=>{
    return(
        <Jobscss>
             <Navbar1/>
             <Navbar2/>
        {/* <Navigation/> */}
        <div className="container">

       
        <h3>Careers</h3>
        <div className="company-profile-par">
       
        <br></br>
        <b><p className="red">People at Effica</p></b>
        <br></br>
        <p>Effica is a company offering the most comprehensive service in the automation industry. The people here nurture a vitalizing, positive environment where employees can develop and grow personally and professionally. Individual thoughts are respected and every individual’s potential is realized, one of the factors that has contributed to the growth of our organization</p>
        <br></br>
        <b><p className="red">Life at Effica</p></b>
        <br></br>
        <p>We strongly believe that life at Effica leaves you a better individual than when you first walked through the doors of Effica. Our culture is all about offering everyone working here with the opportunity to develop accountability, creativity and integrity while growing themselves, their careers, and our business. We offer a host of competitive employee benefits packages that stands on a par with the best of the industrial standards.
</p>
        <br></br>
        <b ><p className="red">Jobs at Effica</p></b>
        <br></br>
        <p>Are you looking for positions currently open at Effica? Explore for jobs that would widen your skills and bring in your potential to perform and help you achieve core expertise. Having a proven track record in automation technology, we invite applications from self motivated employees having prior experience in Industry Automation, Quality control, Designing, Programming & precision manufacturing. Candidates interested in Automation jobs can register now to apply online for any of the advertised vacancies.</p>
        <br></br>
        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        </div>
        <footer className='footer'></footer>
        
        </Jobscss>

    );
}

export default Jobs;