import styled from "styled-components";

const Customerscss = styled.div`

*{
    font-family: Arial, Helvetica, sans-serif;

}

.company-profile-par{
    padding: 0.5rem 0.2rem;
    margin-bottom: 2.5rem;
    border:5px;
    border-style: ridge;
    border-color: lightgrey;

    margin-left: 28rem;
    margin-top: 1rem;
    width:42.5rem;
   

    font-size: 12px;
    color: #000000;
    text-decoration: none;
    line-height: 18px;
    text-align: justify;
}
    

.company-profile-par>p{
    padding: 1rem;

}

h3{
    margin-top: -19.5%;
    margin-left: 28rem;
    color:rgb(2,0,104);
}

.imgbelow{
    position: absolute;
    margin-top:-11.5rem;
    margin-left: 10rem;
    width: 11.5rem;
    
}

.customers{
    margin-left: 7%;
}

.vl {
  border-left: 1px ridge lightgrey;
  height: 543px;
  position: absolute;
  left: 25.2rem;
  margin-left: -3px;
  margin-top: 18rem;
  top: 0;
}

.footer{
    height: 3rem;
    background-color: rgb(82,86,98);
    margin-top: 1rem
}

@media screen and (max-width: 960px){

    .company-profile-par{
        margin: 0;
        width: 80%;
        border: none;
    
    }

    img{
        width:120%;
    }

    .vl{
        display: none;
    }
    .customers{
        margin: 0;
    }

    h3{
        margin: 0;
    }

    .imgbelow{
        display: none;
    }
    .footer{
        margin-top: 100%;
    }
}

`
export default Customerscss;