import Navbar1 from "../components/navbar1";
import Navbar2 from "../drop/Navbar";
// import Navigation from "../components/navigation";
import below from '../images/below.jpg'
import customers from '../images/customers1.jpg'
import Customerscss from "../css/customerscss";

const Customers=()=>{
    return (
        <Customerscss>
             <Navbar1/>
             <Navbar2/>
        {/* <Navigation/> */}
        <h3>Customers</h3>
        <div className="company-profile-par">

        <p>We offer an entire gamut of services to automate clients Industrial process. We are working as a team to achieve their goal by listening to their needs, understanding their business context and committing ourselves to provide a level of service that our clients demand. Take a peek at some of our clients.</p>


           <img  className="customers" src={customers} alt="img loading"></img>


        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        <footer className='footer'></footer>
        
        </Customerscss>
    );
}

export default Customers;