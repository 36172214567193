import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import Companycss from "../../css/company-profilecss";
import below from '../../images/below.jpg'

const Company = ()=>{
    return(

        <Companycss>
        <Navbar1/>
        <Navbar2/>
        {/* <Navigation/> */}
        <h3>Company Profile</h3>
        <div className="company-profile-par">

        <p ><b>Effica Automation Limited</b> is a subsidiary company of <b>Coimbatore Pioneer Fertilizers Limited, a PSG Group of Company</b> doing Industrial Automation on turnkey basis to both domestic and international customers. Being a leading and most renowned brand in the automation industry we provide end-to-end manufacturing and installation of automated assembly that reduce downtime and also provides you a competitive edge.</p>


            <p>Initially incepted in the year 1997 under the name CPF Engineering, we offered Engineering solution for Material Handling, Conveyors, Pressure Vessels and Heat Exchangers. In 2004, based on the experiences gained overtime, we forayed catering to the automation requirements of the industries under the name Effica Automation and by 2007 the Effica Automation was made into a public Limited Company.
            </p>
            <p><b>“Innovation is the key to success”</b> with this belief we provide our customers with innovative solution by bring in technology advancement and engineering expertise together. Effica offers the ideal solutions, to all leading manufacturing and other chemical industries, through automation like various types of conveying systems, robotics and special purpose machines to facilitate the mass production and checking areas in terms of high productivity, accuracy and repeatability.
            </p>
            <h4>Vision</h4>

            <p>To make advancement in custom machine building process and fabricate a sustainable reputation among manufacturers as a reliable company empowered with technocrats to provide innovative solutions for Industrial Automation.
            </p>
            <h4>Mission</h4>

            <p>With professional strength, we focus developing right from the concept to execution, by implementing imperative methodology & constructive system that deliver operational excellence and responds to the exact demand of customer.
            </p>


        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        <footer className='footer'></footer>
        
        </Companycss>

       
    );
}

export default Company;