import styled from "styled-components";

const Ourteam = styled.div`


*{
    font-family: Arial, Helvetica, sans-serif;

}

.company-profile-par{
    padding: 0.5rem 0.2rem;
    margin-bottom: 2.5rem;
    border:1px;
    border-style: ridge;
    border-color: lightgrey;

    
    margin-left: 28rem;
    margin-top: 1.5%;
    width:42.5rem;
   

    font-size: 12px;
    color: #000000;
    text-decoration: none;
    line-height: 18px;
    text-align: justify;
}
    

.company-profile-par>p,.heading{
    padding: 0.58rem;

}



.company-profile-par{
    height: 32.3rem;
}

.pic{
    padding: 0.60rem;
    margin-left: 2rem;
}

.heading{
    color: red;
}

.name{
    position: absolute;
    margin-top: -10.35rem;
    margin-left: 10rem;
}
.details{
    position: absolute;
    margin-top: -8rem;
    text-align: justify;
    width: 29rem;
    margin-left: 10.6rem;
}



.topic{
    margin-top: -19%;
    margin-left: 28rem;
    color:rgb(2,0,104);
}


.imgbelow{
    position: absolute;
    
    margin-top:-11.5rem;
    margin-left: 10rem;
    width: 11.5rem;
    
}

.vl {
  border-left: 1px ridge lightgrey;
  height: 580px;
  position: absolute;
  left: 25.2rem;
  margin-left: -3px;
  margin-top: 18rem;
  top: 0;
}

.footer{
    height: 3rem;
    background-color: rgb(82,86,98);
    margin-top: 1rem;
    
}

@media screen and (max-width: 960px){

    .container{
        display: flex;
        flex-direction: column;
    }

    .company-profile-par{
        margin: 10% 10%;
        width: 75%;
    }

    .details{
        width: 75%;
        margin: 40% 0;
    }
    .heading{
        margin: 0;
    }
    .name{
        margin: 0;
    }
    .imgbelow{
        margin: 250% 10%;
    }

    .topic{
        margin: 0;
    }

    .footer{
        margin-top: 155%;
    }

}

`
export default Ourteam;