import Navbar1 from "../../components/navbar1";
import Navbar2 from "../../drop/Navbar";
// import Navigation from "../../components/navigation";
import below from '../../images/below.jpg'
import machine from '../../images/machine-tending.jpg'
import conveyor from '../../images/conveyor.jpg'
import Materialcss from "../../css/materialcss";

const Material=()=>{
    return(
        <Materialcss>
        <Navbar1></Navbar1>
        <Navbar2/>
        {/* <Navigation/> */}

        <div>

        
        <h3 className="topic">Our Team</h3>
        <div className="company-profile-par">
       
        <p>
        	
        Effica Automation manufactures and integrates full line of Material Handling process for wide range of industries which include engineering component, automotive component, electrical component, FMCG Industries, pharma, cosmetics, Food & Beverages. We deploy appropriate technologies & provide innovative solutions that assist manufacturers to maximize their manufacturing effectiveness. We perform customization depending on clients unique needs and facilitate material handling from production to disposal.
       </p>
       <br/>
       <p>
       Also Effica offers its standard products for Machine tending and conveyor applications.
       </p>
       <h3 className="heading">Machine Tending</h3>

       <h3 className="heading1">Conveyor</h3>
        <img className="pic" src={machine} alt="img loading" />
        <img className="pic1" src={conveyor} alt="img loading"></img>
        
        <p className="details">
        

        The complex process of Machine tending is carried out watching machines activities and performing Machine Loading & unloading process meticulously. With the help of this robot a manufacturer can perform several services such as transferring parts at rapid speed, passing of work from one machine to the other directly before it is loaded onto a pallet etc. Integration of this leads to significant cost-savings. Our programming experience supports customers to arrive at ideal solution for any action whether the machine is a machining center, grinder, gauge, induction furnace, presses, washer or other machine. We at Effica offer the right solution for your machine Tending Operation and thereby help you enjoy increased production with no labour intervention.
    

        </p>
        <h3 className="topic1">Offshore Services</h3>
        <br></br>
        <p className="p">
        Our individual competencies in Design, Controls, Software and Manufacturing made us plan for foraying into providing Out-Sourcing Services for foreign automation companies.
        </p>
        <br/>
        <h3 className="heading2">Testing Systems</h3>

        <p className="p">
        Testing systems provides turnkey solutions in the design, manufacture and support of test and information systems for large-scale repetitive manufacturing. We have designed and built custom test equipment for diverse industry sectors. We set up all our systems at our facility and work out any issues before delivering the system to the customer for final installation.
        </p>
        <br/>
        <h3 className="heading3">Inspection with Machine Vision</h3>
        <p className="p">
        Every manufacturer’s goal is to consistently ensure that every aspect of the production is monitored to ensure the highest level of quality. We at Effica provide custom Cameras programmed with image processing routines that are ideal for high-speed sorting, assembly verification, and packaging inspection. Installation of this smart cameras improve product quality, eliminate production errors and exceed consumer expectations.
        </p>


        </div>

        <div class="vl"></div>
        <img className="imgbelow" src={below} alt="img loading"/>
        
        </div>
        <footer className='footer'></footer>



        </Materialcss>

    );
}

export default Material;